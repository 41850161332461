
















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
  name: 'DropdownMenu',
  components: { Multiselect },
})
export default class DropdownMenu extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private multi!: boolean;
  @Prop() private searchable!: boolean;
  @Prop() private closeOnSelect!: boolean;
  @Prop() private clearOnSelect!: boolean;
  @Prop() private options!: [];
  @Prop() private id!: any;
  @Prop() private isReadOnly!: any;

  private remappedName!: [];
  private secondaryValue: any;
  private internalValue: any;
  private internalValueHandling: any;
  private disableDropdown: any;

  constructor() {
    super();

      this.secondaryValue = '';
      this.internalValue = '';
      this.internalValueHandling = '';
      this.disableDropdown = false;
    }

  @Emit('input') onChange(value: any): any {
    // To avoid mutating the v-model...
    this.internalValue = value;
    //console.log('options', this.options);

    if (value == null) {
        this.secondaryValue = '';
        this.disableDropdown = false;
    }else if(value.HandlingLine.length === 1) {
        this.internalValueHandling = [];
        this.secondaryValue = value.HandlingLine;
        this.internalValueHandling.push(this.secondaryValue[0]);
        this.disableDropdown = true;
      return this.secondaryValue;
    } else {
        this.internalValueHandling = [];
        this.secondaryValue = value.HandlingLine;
        this.disableDropdown = false;
      return value;
    }

    return value;
  }

  @Emit('input') onChangeHandling(value: any): any {
    // To avoid mutating the v-model...
    this.internalValueHandling = value;

    //console.log('this.internalValueHandling', this.internalValueHandling);
    return value;
  }
}
