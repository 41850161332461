































import { Vue, Component } from 'vue-property-decorator';
import { Constants } from '@/constants';
import companySettings from '@/$configs/settings.json';

@Component({
    name: 'BasePage',
})
export default class BasePage extends Vue {
    constructor() {
        super();
    }
}
