


































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
  name: 'ProductPremiumQuantity',
  components: { Multiselect },
})
export default class ProductPremiumQuantity extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private multi!: boolean;
  @Prop() private searchable!: boolean;
  @Prop() private closeOnSelect!: boolean;
  @Prop() private clearOnSelect!: boolean;
  @Prop() private options!: [];
  @Prop() private id!: any;
  @Prop() private isReadOnly!: any;
  @Prop() private isRequired!: any;

  private remappedName!: [];
  private secondaryValue: any;
  private internalValue: any;
  private internalValueHandling: any;
  private quantityValue: any;

  constructor() {
    super();

    this.secondaryValue = '';
    this.internalValue = '';
    this.quantityValue = '';
    this.internalValueHandling = [];

  }


  @Emit('input') onChange(value: any): any {
    // To avoid mutating the v-model...
    this.internalValue = value;

    if (value == null) {
      this.secondaryValue = '';
    }else if(value.HandlingLine.length === 1) {
      this.internalValueHandling = [];
      this.secondaryValue = value.HandlingLine;
      this.internalValueHandling.push(this.secondaryValue[0]);
      this.quantityValue = 0;
      return this.secondaryValue;
    } else {
      this.secondaryValue = value.HandlingLine;
      return value;
    }

    return value;
  }

  @Emit('input') onChangeHandling(value: any): any {
    // To avoid mutating the v-model...
    this.internalValueHandling = value;

    return value;
  }


  @Emit('blur') onChangeQuantity(value: number): any {
    this.quantityValue = value
    return {
      ProductPremium: this.internalValueHandling,
      HandlingLineQuantity: this.quantityValue,
    };

  }


}
