













import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'PageSeparator'
})
export default class PageSeparator extends Vue {
    @Prop() private pageNumber!: number;
    @Prop() private pageCount!: number;
    @Prop() private isDisabled!: boolean;
    @Prop() private disableButton!: boolean;


    @Emit('previous') navigateToPreviousPage(
        pageNumber: number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        pageCount: number
    ): number | null {
        if (pageNumber - 1 === 0) return null;
        return pageNumber - 1;
    }

    @Emit('next') navigateToNextPage(
        pageNumber: number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        pageCount: number
    ): number | null {
        return pageNumber + 1;
    }


}
