




























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'CheckBox'
})
export default class CheckBox extends Vue {
    @Prop() private name!: string;
    @Prop() private label!: string;
    @Prop() private value!: string;
    @Prop() private options!: [];
    @Prop() private checked!: boolean | string;
    @Prop() private isReadOnly!: any;
    @Prop() private isRequired!: boolean;
    private gotValue!: boolean;

    constructor() {
        super();
        if (this.value != null) {

            this.gotValue = (this.value === 'true')
        }

    }

    @Emit('input') onChange(isChecked: boolean | string): string {
        if (this.isRequired === true) {
            if (isChecked === false) {
                return '';
            }
            else {
                return isChecked.toString();
            }
        }
        return isChecked.toString();
    }
}
