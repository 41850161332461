







































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({ 
  name: 'DatePicker',
})
export default class DatePicker extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string; 
  @Prop() private locale!: string;
  @Prop() private minAge!: number;
  @Prop() private isReadOnly!: any;

  private date: Date;
  private selectedDate: Date | string | null;
  private dateParts: any;
  private disable: boolean;
  private disableDate: Date;


  constructor() {
    super();
    this.date = new Date();
    this.selectedDate = null;

    if (this.value != null && this.value.length != 0) {
      this.dateParts = this.value.split("/");
      const getYear = this.dateParts[2].split(" ");
      const dateObject = new Date(+getYear[0], this.dateParts[1] - 1, +this.dateParts[0]); 
      this.selectedDate = dateObject
    }
    else{
      this.selectedDate = null;
    }

    this.disable = false;
    if(this.isReadOnly == false){
      this.disable = true;
    }

    this.disableDate = new Date();

  }

  private moment(date: Date): moment.Moment {
    return moment(date);
  }

  @Emit('input') onChange(selectedDate: Date): string {
    return this.moment(selectedDate).format('DD-MM-YYYY');
  }
}
