



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Tile'
})
export default class Tile extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private items!: Array<{
    id: number;
    title: string;
    summary: string;
    thumbnail: any;
  }>;

  private selectedItem!: {
    id: number;
    title: string;
    summary: string;
    thumbnail: any;
  };

  constructor() {
    super();
  }
}
