

























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import ExtraProductPremiumQuantity from '@/components/form-builder/ExtraProductPremiumQuantity.vue';

@Component({
  name: 'ProductPremiumQuantity',
  components: { Multiselect },
})
export default class ProductPremiumQuantity extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private multi!: boolean;
  @Prop() private searchable!: boolean;
  @Prop() private closeOnSelect!: boolean;
  @Prop() private clearOnSelect!: boolean;
  @Prop() private options!: [];
  @Prop() private id!: any;
  @Prop() private isReadOnly!: any;
  @Prop() private isRequired!: any;

  private remappedName!: [];
  private secondaryValue: any;
  private internalValue: any;
  private internalValueHandling: any;
  private components: any;
  private testArray: any;
  private sendArray: any;
  private idPlus: any;


  constructor() {
    super();

    this.secondaryValue = '';
    this.internalValue = '';
    this.internalValueHandling = [];
    this.components = [];
    this.testArray = [];
    this.components.push(ExtraProductPremiumQuantity);
    this.idPlus = 0;
    this.sendArray = [];
    //console.log(this.components); 
  }

  mounted(){
    //console.log('test', this.secondaryValue);

  }

  addComponent(){
    this.idPlus++
    this.components.push(ExtraProductPremiumQuantity);    
    //console.log('component added', this.components);
  }

  deleteComponent(){
    this.components.splice(-1);
    this.testArray.splice(-1);
    //console.log('component remoced', this.components);
  }

  @Emit('input') onChange(value: any, index: any): any {
    //console.log('index', index)
    if(typeof this.testArray[index] === 'undefined')
    {
      this.testArray.push(value);
      

      return this.testArray
    }

    this.testArray[index] = value
  
    //console.log('this.testArray', this.testArray)
    return this.testArray;
  }

  @Emit('blur') onBlur(value: any, index: any): any {
    //console.log('index', index)
    if(typeof this.testArray[index] === 'undefined')
    {
      this.testArray.push(value);
      

      return this.testArray
    }

    this.testArray[index] = value
  
    //console.log('this.testArray', this.testArray)
    return this.testArray;
  }

}
