





















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'InputText'
})
export default class InputText extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private className!: string;
  @Prop() private isReadOnly!: any;
  constructor() {
    super();
  }

  @Emit('input') onInput(value: string): string {
    return value;
  }
}
