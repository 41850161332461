


















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'InputPassword'
})
export default class InputPassword extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private isReadOnly!: any;

  @Emit('input') onChange(value: string): string {
    return value;
  }
}
