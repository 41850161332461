







import { Component, Prop, Emit, Vue } from 'vue-property-decorator';


@Component({
    name: 'Loading'
})
export default class Loading extends Vue {

    constructor() {
        super();

    }


}
