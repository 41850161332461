


















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'InputNumber'
})
export default class InputNumber extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: number;
  @Prop() private placeholder!: string;
  @Prop() private isReadOnly!: any;
  @Emit('input') onChange(value: number): number {
    return value;
  }
}
