
















































































































































































































import { Component, Prop, Emit, Ref, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import CoolLightBox from 'vue-cool-lightbox';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFileEncode,
    FilePondPluginFileValidateSize
);

@Component({
    name: 'PurchaseRegistrationSerialInputs',
    components: { Multiselect, FilePond, CoolLightBox },
})
export default class PurchaseRegistrationSerialInputs extends Vue {
    @Prop() private name!: string;
    @Prop() private label!: string;
    @Prop() private labelList!: any;
    @Prop() private value!: string;
    @Prop() private placeholder!: string;
    @Prop() private multi!: boolean;
    @Prop() private searchable!: boolean;
    @Prop() private closeOnSelect!: boolean;
    @Prop() private clearOnSelect!: boolean;
    @Prop() private options!: any;
    @Prop() private id!: any;
    @Prop() private isReadOnly!: any;
    @Prop() private isRequired!: any;
    @Prop() private locale!: string;
    @Prop() private uploadText!: string;
    @Prop() private fileTypes!: string;
    @Prop() private defaultValuesPurchaseRegistrations!: any;

    private remappedName!: [];
    private secondaryValue: any;
    private internalValue: any;
    private internalValueHandling: any;
    private quantityValue: any;
    private serialNumberValue: any;
    private amountValue: any;

    private date: Date;
    private selectedDate: Date | string | null;
    private dateParts: any;
    private disable: boolean;
    private disableDate: Date;
    private dateFormat: string;
    private purchaseDate: any;

    private uploadedFiles: any;
    private uploadedSerial: any;
    private lightboxIndex: number | null;
    private uploadFile: string;
    private uploadSerial: string;
    private imageData: any;
    private imageSerialData: any;
    private i: any;

    private labelListTranslation: any;
    private internalRetailerValue: any;
    private editInfo: any;

    constructor() {
        super();
        this.i = 0;
        this.secondaryValue = '';
        this.internalValue = '';
        this.quantityValue = '';
        this.serialNumberValue = '';
        this.amountValue = '';
        this.internalValueHandling = [];

        this.date = new Date();
        this.selectedDate = null;
        this.dateFormat = 'yyyy-MM-dd';
        if (this.purchaseDate != undefined) {
            this.dateParts = this.purchaseDate.split("/");
            const getYear = this.dateParts[2].split(" ");
            const dateObject = new Date(+getYear[0], this.dateParts[1] - 1, +this.dateParts[0]);
            this.selectedDate = dateObject
        }
        else {
            this.selectedDate = null;
        }

        this.disable = false;
        if (this.isReadOnly == false) {
            this.disable = true;
        }
        this.disableDate = new Date();
        this.purchaseDate = '';

        this.uploadedFiles = [];
        this.uploadedSerial = [];
        this.lightboxIndex = null;
        this.uploadFile = this.$parent.$i18n.t('component.filepond.labelIdle').toString();
        this.uploadSerial = this.$parent.$i18n.t('component.filepond.labelIdle').toString();
        this.imageData = [];
        this.imageSerialData = [];

        this.labelListTranslation = this.labelList;
        this.internalRetailerValue = '';
        this.editInfo = this.defaultValuesPurchaseRegistrations;

        //console.log(this.editInfo);

        if (this.editInfo != null) {
            console.log("this.editInfo", this.editInfo)
            this.internalValue = this.editInfo.productPremium[0];
            this.secondaryValue = this.editInfo.productPremium[0].HandlingLine;
            const premiumValue = this.editInfo.productPremium[0].HandlingLine[0];
            this.internalValueHandling.push(premiumValue);
            this.quantityValue = this.editInfo.quantity;
            this.purchaseDate = this.moment(this.editInfo.purchaseDate).format('DD-MM-YYYY');
            this.selectedDate = new Date(this.editInfo.purchaseDate);
            this.amountValue = this.editInfo.totalAmount;
            this.internalRetailerValue = this.editInfo.storePurchased[0];
            this.serialNumberValue = this.editInfo.serialNumber;
        }
    }

    private moment(date: Date): moment.Moment {
        return moment(date);
    }

    private setLightboxIndex(index: number): void {
        if (index < 0) return;
        this.lightboxIndex = index;
    }

    @Ref('uploader') private uploader: any;
    @Ref('uploaderSerial') private uploaderSerial: any;

    @Emit('input') onChange(value: any): any {
        // To avoid mutating the v-model...
        this.internalValue = value;

        if (value == null) {
            console.log("removed1");
            this.secondaryValue = '';
            this.internalValueHandling = [];
            return {
                ProductPremium: this.internalValueHandling,
                HandlingLineQuantity: this.quantityValue,
                PurchaseDate: this.purchaseDate,
                SerialNumber: this.serialNumberValue,
                SerialImage: this.imageSerialData,
                TotalAmount: this.amountValue,
                InvoiceImage: this.imageData,
                StorePurchased: this.internalRetailerValue?.retailerLocationId
            };
        } else if (value.HandlingLine.length === 1) {
            this.internalValueHandling = [];
            this.secondaryValue = value.HandlingLine;
            this.internalValueHandling.push(this.secondaryValue[0]);
            this.quantityValue = 0;
            return {
                ProductPremium: this.internalValueHandling,
                HandlingLineQuantity: this.quantityValue,
                PurchaseDate: this.purchaseDate,
                SerialNumber: this.serialNumberValue,
                SerialImage: this.imageSerialData,
                TotalAmount: this.amountValue,
                InvoiceImage: this.imageData,
                StorePurchased: this.internalRetailerValue?.retailerLocationId
            }
        } else {
            this.secondaryValue = value.HandlingLine;
            return {
                ProductPremium: this.internalValueHandling,
                HandlingLineQuantity: this.quantityValue,
                PurchaseDate: this.purchaseDate,
                SerialNumber: this.serialNumberValue,
                SerialImage: this.imageSerialData,
                TotalAmount: this.amountValue,
                InvoiceImage: this.imageData,
                StorePurchased: this.internalRetailerValue?.retailerLocationId
            };
        }
    }

    @Emit('input') onChangeHandling(value: any): any {
        // To avoid mutating the v-model...
        this.internalValueHandling = value;

        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };
    }

    @Emit('blur') onChangeQuantity(value: number): any {
        this.quantityValue = value
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };

    }

    @Emit('blur') onChangeSerialNumber(value: any): any {
        this.serialNumberValue = value
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };

    }

    @Emit('input') onChangeDate(selectedDate: Date): any {

        this.purchaseDate = this.moment(selectedDate).format('YYYY-MM-DD');
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };
    }


    @Emit('addfile') onAddFile(): [] | null {
        const files = this.uploader.getFiles();
        if (!Array(files).length) return null;
        const fileDataUrl = files[0].getFileEncodeDataURL();
        this.uploadedFiles.push(fileDataUrl);

        function removeDuplicates(data: any) {
            return data.filter(
                (value: any, index: any) => data.indexOf(value) === index
            );
        }

        this.uploadedFiles = removeDuplicates(this.uploadedFiles);
        this.onRetrievedDataURL(this.uploadedFiles);
        console.log('on upload', this.uploadedFiles);
        return this.uploadedFiles;
    }

    @Emit('addfile') onAddFileSerial(): [] | null {
        const filesSerial = this.uploaderSerial.getFiles();
        if (!Array(filesSerial).length) return null;
        const fileDataUrl = filesSerial[0].getFileEncodeDataURL();
        this.uploadedSerial.push(fileDataUrl);

        console.log(this.uploadedSerial);

        function removeDuplicatesSerial(data: any) {
            return data.filter(
                (value: any, index: any) => data.indexOf(value) === index
            );
        }

        this.uploadedSerial = removeDuplicatesSerial(this.uploadedSerial);
        this.onRetrievedSerialDataURL(this.uploadedSerial);
        console.log('on upload Serial', this.uploadedSerial);
        return this.uploadedSerial;
    }

    @Emit('input') onRetrievedDataURL(dataUrl: string | string[]) {
        //return dataUrl;
        console.log(dataUrl, "normal invoice");
        this.imageData = dataUrl;
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };
    }

    @Emit('input') onRetrievedSerialDataURL(dataUrl: string | string[]) {
        //return dataUrl;
        console.log(dataUrl, "serial");
        this.imageSerialData = dataUrl;
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Emit('input') onRemoveFile(file: any): any {

        console.log('this.uploadedFiles', this.uploadedFiles);
        console.log('file.id', file.source);

        for (let i = 0; i < this.uploadedFiles.length; i++) {
            if (this.uploadedFiles[i] === file.source) {
                console.log("gelijk");
                this.uploadedFiles = [];
                this.imageData = [];
                return {
                    ProductPremium: this.internalValueHandling,
                    HandlingLineQuantity: this.quantityValue,
                    PurchaseDate: this.purchaseDate,
                    SerialNumber: this.serialNumberValue,
                    SerialImage: this.imageSerialData,
                    TotalAmount: this.amountValue,
                    InvoiceImage: this.imageData,
                    StorePurchased: this.internalRetailerValue?.retailerLocationId
                }
            }
        }
        console.log('this.uploadedFiles', this.uploadedFiles);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }

    @Emit('input') onRemoveSerialFile(file: any): any {

        for (let i = 0; i < this.uploadedSerial.length; i++) {
            if (this.uploadedSerial[i] === file.source) {
                console.log("gelijk");
                this.uploadedSerial = [];
                this.imageSerialData = [];
                return {
                    ProductPremium: this.internalValueHandling,
                    HandlingLineQuantity: this.quantityValue,
                    PurchaseDate: this.purchaseDate,
                    SerialNumber: this.serialNumberValue,
                    SerialImage: this.imageSerialData,
                    TotalAmount: this.amountValue,
                    InvoiceImage: this.imageData,
                    StorePurchased: this.internalRetailerValue?.retailerLocationId
                }
            }
        }
        console.log('this.uploadedFiles', this.uploadedFiles);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }

    @Emit('input') onChangeRetailer(value: any): any {
        // To avoid mutating the v-model...

        this.internalRetailerValue = value;
        console.log('this.internalRetailerValue', this.internalRetailerValue);
        return {
            ProductPremium: this.internalValueHandling,
            HandlingLineQuantity: this.quantityValue,
            PurchaseDate: this.purchaseDate,
            SerialNumber: this.serialNumberValue,
            SerialImage: this.imageSerialData,
            TotalAmount: this.amountValue,
            InvoiceImage: this.imageData,
            StorePurchased: this.internalRetailerValue?.retailerLocationId
        };
    }


}
