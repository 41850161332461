




















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
    name: 'RetailerRadioButton',
})
export default class RetailerRadioButton extends Vue {
    @Prop() private name!: string;
    @Prop() private label!: string;
    @Prop() private values!: Array<string>;
    @Prop() private options!: [];
    @Prop() private isReadOnly!: any;

    @Emit('input') onChange(value: string): string {
        return value;
    }
}
