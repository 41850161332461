















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import PurchaseRegistrationSerialInputs from '@/components/form-builder/PurchaseRegistrationSerialInputs.vue';

@Component({
  name: 'PurchaseRegistrationSerial',
  components: { Multiselect },
})
export default class PurchaseRegistrationSerial extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: any;
  @Prop() private labelList!: any;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private multi!: boolean;
  @Prop() private searchable!: boolean;
  @Prop() private closeOnSelect!: boolean;
  @Prop() private clearOnSelect!: boolean;
  @Prop() private options!: [];
  @Prop() private id!: any;
  @Prop() private isReadOnly!: any;
  @Prop() private isRequired!: any;
  @Prop() private defaultValuesPurchaseRegistrations!: any;

  private remappedName!: [];
  private secondaryValue: any;
  private internalValue: any;
  private internalValueHandling: any;
  private components: any;
  private dataArray: any;
  private sendArray: any;
  private idPlus: any;


  private quantityValue: any;
  private amountValue: any;

  private date: Date;
  private selectedDate: Date | string | null;
  private dateParts: any;
  private dateFormat: string;
  private purchaseDate: any;



  constructor() {
    super();

    this.secondaryValue = '';
    this.internalValue = '';
    this.internalValueHandling = [];
    this.components = [];
    this.dataArray = [];

    if(this.defaultValuesPurchaseRegistrations != null && this.$route.params.registrationId != undefined ){
      for (let index = 0; index < this.defaultValuesPurchaseRegistrations.length; ++index) {
          this.components.push(PurchaseRegistrationSerialInputs);
        this.dataArray[index]= {};
        //this.dataArray = Array(this.defaultValuesPurchaseRegistrations.length);
      }
    }
    else{
        this.components.push(PurchaseRegistrationSerialInputs);
    }

    
    this.idPlus = 0;

    this.sendArray = [];
    this.date = new Date();
    this.selectedDate = null;
    this.dateFormat = 'yyyy-MM-dd';
    console.log('this.dataArray', this.dataArray); 
    //console.log('this.components', this.components);    
  }

  mounted(){
    //console.log('test', this.secondaryValue);

  }

  addComponent(){
    this.idPlus++
      this.components.push(PurchaseRegistrationSerialInputs);
    //console.log('component added', this.components);
  }

  deleteComponent(){
    this.components.splice(-1);
    this.dataArray.splice(-1);
    //console.log('component remoced', this.components);
  }

  @Emit('input') onChange(value: any, index: any): any {
    //console.log('onchange', this.dataArray, index);


    if(typeof this.dataArray[index] === 'undefined')
    {
      this.dataArray[index]= value;  
      return this.dataArray
    }

    this.dataArray[index] = value  
    return this.dataArray;
  }

  @Emit('blur') onBlur(value: any, index: any): any {
    console.log('onblur', this.dataArray, index); 

    if(typeof this.dataArray[index] === 'undefined')
    {
      this.dataArray.push(value);     
      return this.dataArray
    }

    this.dataArray[index] = value
       
    this.onChange(value, index);
    //return this.dataArray;
  }

}
