


























import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

@Component({
  name: 'RetailerLocation',
  components: { Multiselect },
})
export default class RetailerLocation extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private multi!: boolean;
  @Prop() private searchable!: boolean;
  @Prop() private closeOnSelect!: boolean;
  @Prop() private clearOnSelect!: boolean;
  @Prop() private options!: [];
  @Prop() private id!: any;
  @Prop() private isReadOnly!: any;

  private remappedName!: [];
  private secondaryValue: any;
  private internalValue: any;
  private internalValueHandling: any;
  private chosenRetailer: any;

  constructor() {
    super();

    this.secondaryValue = '';
    this.internalValue = '';
    this.internalValueHandling = '';
    if (this.value != null && this.value.length != 0) {
      this.internalValue = this.options.find( (p: any) => p.retailerLocationId == this.value );
    }
 
  }

  @Emit('input') onChange(value: any): any {
    // To avoid mutating the v-model...
    this.internalValue = value;
    if (value == null) {
      this.secondaryValue = '';
    } else {
      this.secondaryValue = value.HandlingLine;
    }
    //console.log(value.retailerLocationId);
    return value.retailerLocationId;
  }

  @Emit('input') onChangeHandling(value: any): any {
    // To avoid mutating the v-model...
    this.internalValueHandling = value;
    return value;
  }
}
