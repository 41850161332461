






























import { Component, Prop, Vue } from 'vue-property-decorator';
import FormBuilder from '@/components/form-builder/FormBuilder.vue';
import { Constants } from '@/constants';
import companySettings from '../$configs/settings.json';
import Loading from '@/components/form-builder/Loading.vue';
import dummyData from '@/assets/json/data.json';

@Component({
    name: 'RegistrationForm',
    components: { FormBuilder, Loading },
})
export default class RegistrationForm extends Vue {
    @Prop() private msg!: string;
    public formData: {}[] = [];
    public formSchema: any = [];
    public formLocaleId!: number;
    locale!: string;
    private currentDate: any;

    campaignId!: number;
    campaignCode!: string;
    checkMode!: any;
    expiredCampaign!: any;
    upcomingCampaign!: any;
    startcampaigndate!: any;

    private loaded: boolean;
    constructor() {
        super();
        this.loaded = false;
        this.expiredCampaign = '';
        this.upcomingCampaign = '';
    }

    data() {
        return {
            loaded: Boolean
        }
    }

    async getCampaignForForm() {
        const locale = this.$parent.$route.params.locale;
        const campaignCode = this.$route.params.campaignCode;
        const url = `/Api/GetCampaignForForm?currentLocale=${locale}&currentCampaignCode=${campaignCode}`;
        const getCampaignForFormRequest = await fetch(url, { method: 'GET', credentials: 'include', redirect: 'follow', mode: 'same-origin' });
        const getCampaignForFormResponse = await getCampaignForFormRequest.json();

        return getCampaignForFormResponse;
    }

    async getFormAndProductHandling(currentCampaignId: any) {
        const locale = this.$parent.$route.params.locale;
        const url = `/Api/GetFormAndProductHandling?currentLocale=${locale}&currentCampaignId=${currentCampaignId}`;
        const getCampaignForFormRequest = await fetch(url, { method: 'GET', credentials: 'include', redirect: 'follow', mode: 'same-origin' });
        const getCampaignForFormResponse = await getCampaignForFormRequest.json();

        return getCampaignForFormResponse;
    }

    async mounted() {
        this.loaded = false;
        this.formLocaleId = 0;
        this.locale = this.$route.params.locale;
        this.campaignCode = this.$route.params.campaignCode;

        setTimeout(() => {
            this.getCampaignForForm().then(data => {
                const parsedData = JSON.parse(data);

                if (this.$route.params.registrationId === undefined) {
                    const orginalDate = parsedData.result.campaignEndDate.split(/[-+ :T]/);
                    const endDate = new Date(orginalDate[0], orginalDate[1] - 1, orginalDate[2]);

                    const orginaleDate = parsedData.result.campaignStartDate.split(/[-+ :T]/);
                    const startDate = new Date(orginaleDate[0], orginaleDate[1] - 1, orginaleDate[2]);

                    const imgurl = parsedData.result.bannerImagePath;
                    if (imgurl != null && imgurl.trim() != "") {
                        document.getElementById('header-img')!.style.backgroundImage = "url(" + imgurl + ")";
                    }

                    this.startcampaigndate = startDate.toLocaleDateString('nl-NL');
                    this.currentDate = new Date();
                    this.expiredCampaign = this.currentDate > endDate;
                    this.upcomingCampaign = this.currentDate < startDate;
                    if (this.expiredCampaign || this.upcomingCampaign === true) {
                        this.loaded = true;
                        return;
                    }
                }

                this.campaignId = parsedData.result.campaignId;

                this.getFormAndProductHandling(this.campaignId).then(data => {
                    const parsedData = JSON.parse(data);

                    const fieldBlocks = [];
                    for (let i = 0; i < parsedData.formbuilder.exportBlocks.length; i++) {
                        const ArrayObjects = parsedData.formbuilder.exportBlocks[i].exportFields;
                        for (let j = 0; j < ArrayObjects.length; j++) {
                            fieldBlocks.push(ArrayObjects[j]);
                        }
                    }
                    this.formSchema.push(...fieldBlocks);

                    return fieldBlocks;
                });
            });
            this.loaded = true;
        }, 500);
    }
}
