






















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'RadioButton'
})
export default class RadioButton extends Vue {
  @Prop() private name!: string;
  @Prop() private label!: string;
  @Prop() private values!: Array<string>;
  @Prop() private isReadOnly!: any;
  @Prop() private options!: any;

  constructor() {
    super();
    //console.log("name", value);
  }

  @Emit('input') onChange(value: string): string {
    return value;
  }
}
